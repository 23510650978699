@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Aktiv Grotesk;
  src: url("./fonts/AktivGrotesk-Thin.woff") format("woff");
  font-weight: 100;
}
@font-face {
  font-family: Aktiv Grotesk;
  src: url("./fonts/AktivGrotesk-Hair.woff") format("woff");
  font-weight: 200;
}
@font-face {
  font-family: Aktiv Grotesk;
  src: url("./fonts/AktivGrotesk-Light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: Aktiv Grotesk;
  src: url("./fonts/AktivGrotesk-Regular.woff") format("woff");
  font-weight: normal;
}
@font-face {
  font-family: Aktiv Grotesk;
  src: url("./fonts/AktivGrotesk-Medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: Aktiv Grotesk;
  src: url("./fonts/AktivGrotesk-Bold.woff") format("woff");
  font-weight: bold;
}
@font-face {
  font-family: Aktiv Grotesk;
  src: url("./fonts/AktivGrotesk-XBold.woff") format("woff");
  font-weight: 800;
}
@font-face {
  font-family: Aktiv Grotesk;
  src: url("./fonts/AktivGrotesk-Black.woff") format("woff");
  font-weight: 900;
}

body {
  margin: 0;
  font-family: "Aktiv Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.text-blue {
  color: #2222ff;
}

.text-red {
  color: #dd0000;
}

/* Tailwind extended classes */

.not-first\:border-t:not(:first-child) {
  @apply border-t;
}

/* Resetters for browsers and iOS */
textarea,
input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  outline: none !important;
  box-shadow: none !important;
}

input:disabled {
  -webkit-text-fill-color: black !important;
  color: black !important;
  opacity: 1 !important;
}

input:disabled::placeholder {
  opacity: 0.3 !important;
}

input:focus,
select:focus,
textarea:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
}

input[type="tel"] {
  font-weight: 500;
  font-size: 14px;
}

/* PhoneInput library */
.PhoneInputInput:disabled {
  background-color: #f7f7f7 !important;
}
.PhoneInputCountryIcon {
  margin-right: 10px !important;
}
.PhoneInputCountrySelectArrow {
  display: none !important;
}
.PhoneInputInput {
  /* Same style-input font size */
  font-size: 17px !important;
}

/* START Styles defined in Figma Library */

.text-body {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.text-body-bold {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
}

.text-body-link {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-decoration: underline;
}

.text-body-xbold {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
}

.text-small {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
}

.text-small-bold {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
}

.text-mid {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.text-mid-bold {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
}

.text-title {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
}

.text-title-medium {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
}

.text-title-medium-underline {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  text-decoration: underline;
}

.text-title-xbold {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
}

.text-title-bold {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
}

.text-h1 {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
}

.text-h2 {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
}

.text-h3 {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
}

.text-h4 {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
}

.text-h5 {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
}

.text-h6 {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
}

.text-h7 {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

/* Styles to be reviewed or deleted */

.style-body-copy {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
}

.style-body-large {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
}



.style-caption {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
}

.style-caption-small {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}

.style-heading {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
}

.style-subheading {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
}

.style-title {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
}

.style-input {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
}

.style-subtitle {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
}

.style-body-copy-link {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  text-decoration: underline;
}

.style-subtext {
  font-family: Aktiv Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

/* END Styles defined in Figma Library */